<template>
  <div class="content-wrapper">
    <div class="employees main-content">

      <div class="page-header">
        <h1>Employees</h1>
      </div>

      <div class="view-toolbars">
        <ItemFilter class="fitler" placeholder="Enter Employee Name" @search="searchEmployee" @reset="resetFilter">
          <el-form class="search-form" :model="searchForm">
            <div class="row">
              <el-form-item label="Staff ID">
                <el-input v-model="searchForm.employee_id"></el-input>
              </el-form-item>
              <el-form-item label="Position">
                <el-input v-model="searchForm.position"></el-input>
              </el-form-item>
              <el-form-item label="Department">
                <el-input v-model="searchForm.department"></el-input>
              </el-form-item>
              <el-form-item label="Phone Number">
                <el-input v-model="searchForm.phone_number"></el-input>
              </el-form-item>
            </div>
          </el-form>
        </ItemFilter>

        <div class="tool">
          <router-link :to="{name:'AddEmployee'}" v-if="checkIsAdmin()">
            <button class="btn btn-primary">
              <i class="el-icon-plus"></i>
              New
            </button>
          </router-link>
        </div>
      </div>

      <div class="employee-list">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Staff ID</th>
              <th scope="col">Name</th>
              <th scope="col">Position</th>
              <th scope="col">Department</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="employee in employees">
              <th scope="row">{{employee.employee_id}}</th>
              <td>{{employee.employee_name}}</td>
              <td>{{employee.position}}</td>
              <td>{{employee.department}}</td>
              <td>{{employee.phone_number}}</td>
              <td class="action">
                <router-link :to="{name:'Employee', params: {id: employee.id}}">View</router-link>
                <a href="#" v-if="checkIsAdmin()" class="delete-link" @click="deleteEmployee(employee.id)">Delete</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ItemFilter from '@/components/ItemFilter.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';

export default {
  name: 'Employees',
  components: {
    ItemFilter,
  },
  data(){
    return {
      searchForm: {
        employee_id: '',
        position: '',
        department: '',
        phone_number: '',
      },
      originEmployees: [],
      employees: [],
    }
  },
  mounted(){
    this.loadEmployees();
  },
  methods:{
    checkIsAdmin(){
      const currentUser = this.currentUser;
      if(currentUser){
        const isAdmin = Common.checkIsAdmin(currentUser);
        return isAdmin;
      }
      return false;
    },
    async loadEmployees(){
      const loginInfo = Common.getLoginInfo();
      try{
        const employees = await Hr.loadAllEmployees(this.apiUrl, loginInfo);
        this.originEmployees = employees;
        this.employees = employees;
      }catch(err){
        console.log(err);
      }
    },
    resetFilter(){
      //Clean Filter
      for(let i = 0; i < Object.keys(this.searchForm).length; i++){
        const searchKey = Object.keys(this.searchForm)[i];
        this.searchForm[searchKey] = '';
      }
      this.employees = this.originEmployees;
    },
    searchEmployee(textSearchValue){
      this.searchForm['employee_name'] = textSearchValue;
      const searchFilter = this.searchForm;
      const newEmployees = Common.filterItems(searchFilter, this.originEmployees);
      this.employees = newEmployees;
    },
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
    async deleteEmployee(employeeId){
      const loginInfo = Common.getLoginInfo();
      try{
        await Hr.deleteEmployee(this.apiUrl, employeeId, loginInfo);
        const newEmployees = this.employees.filter(function(item) {
          return item.id !== employeeId;
        });
        this.employees = newEmployees;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   currentUser: state => state.currentUser,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.view-toolbars{
  display: flex;
  position: relative;
  .fitler{
    flex: 1;
  }
  .el-icon-plus{
    font-size: 1.2em;
  }
}
.employee-list{
  .action{
    a{
      display: inline-block;
      margin-right: 10px;
    }
    .delete-link{
      color: red;
    }
  }
}
</style>
